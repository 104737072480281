<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-02-14 10:32:02
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 09:25:30
 * @FilePath: /siana_admin/src/views/gpt_key/components/modal.vue
-->
<template>
  <!--弹窗-->
  <el-dialog :title="title"
    :visible.sync="dialogVisible"
    width="500px"
    :fullscreen="phone"
    :close-on-click-modal="false"
    :before-close="handleClose"
    class="upload_box">
    <!--form-->

    <div class="key-value-box">key：{{ ruleForm.key }}</div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" :label-position="position" label-width="100px">

      <el-form-item label="GPTKEY" prop="key" v-if="!isEdit">
        <el-input  v-model="ruleForm.key" placeholder="请输入" />
      </el-form-item>

      <el-form-item label="排序" prop="sort">
        <el-input v-model="ruleForm.sort" type="Number" placeholder="请输入" />
      </el-form-item>

      <el-form-item label="是否启用" prop="state">
        <el-select v-model="ruleForm.state" style="width:100%" placeholder="请选择">
          <el-option label="启用" :value="0" />
          <el-option label="停用" :value="1" />
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="GPT类型" prop="type">
        <el-select v-model="ruleForm.type" style="width:100%" placeholder="请选择">
          <el-option label="GPT3.5" :value="3" />
          <el-option label="GPT4" :value="4" />
        </el-select>
      </el-form-item> -->
      <el-form-item label="API类型" prop="apiType">
        <el-select v-model="ruleForm.apiType" style="width:100%" placeholder="请选择">
          <el-option label="OpenAI" :value="1" />
          <el-option label="微软Azure OpenAI" :value="2" />
          <el-option label="智谱清言" :value="3" />
          <el-option label="火山引擎" :value="4" />
        </el-select>
      </el-form-item>

      <el-form-item label="第三方url前缀" prop="url">
        <el-input v-model="ruleForm.url" placeholder="接口" />
      </el-form-item>

        <el-form-item label="模型" prop="optionsList">
            <el-checkbox-group v-model="ruleForm.optionsList">
                <el-checkbox v-for="(item, index) in optionsCheckbox" :label="item.label" :key="index">{{ item.name }}</el-checkbox>
            </el-checkbox-group>
        </el-form-item>

      <!-- <el-form-item label="向量化" prop="embedding">
        <el-select v-model="ruleForm.embedding" style="width:100%" placeholder="请选择">
          <el-option label="可以" :value="1" />
          <el-option label="不行" :value="0" />
        </el-select>
      </el-form-item> -->
      <div class="tips">知识库训练调用频率比较高，只有支持高频的KEY才开启向量化</div>

    </el-form>
    <!-- 表单结束 -->
    <!--操作按钮-->
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <!--确定-->
      <el-button type="primary" :disabled="loading" @click="handleSubmit">
        <i v-if="loading" class="el-icon-loading"></i>
        确定
        </el-button>
    </span>
  </el-dialog>
  <!--弹窗 end-->
</template>

<script>
// 引入组件及方法
export default {
  name: 'Yxb3NewModal',

  data() {
    return {
      phone: false,
      type: 'state',
      // checked: false, //是否覆盖数据
      loading: false, // loading状态
      dialogVisible: false, // 弹窗显隐状态
      // 标题
      position: 'right',
      title: '', // 弹窗标题
      // 表单
      ruleForm: {
        key: '',
        type: '',
        state: '',
        apiType: '',
        sort: 0,
        url:"",
        embedding:0,
        optionsList:[]
      },
        optionsCheckbox:[
            // {
            //     label:1,
            //     name:"对话"
            // },{
            //     label:2,
            //     name:"向量化"
            // },{
            //     label:4,
            //     name:"语音"
            // },{
            //     label:8,
            //     name:"画图"
            // }
        ],
      // 校验规则
      rules: {
        key: [{ required: true, message: '请输入', trigger: 'change' }],
        type: [{ required: true, message: '请输入', trigger: 'change' }],
        state: [{ required: true, message: '请选择', trigger: 'change' }],
        sort: [{ required: true, message: '请输入', trigger: 'change' }],
        apiType: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      isEdit:false
    }
  },

  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    this.position = this.phone == true ? 'top' : 'right'
    this.loadAllModel()
    
  },

  methods: {
    // 弹窗信息设置
    open(page, data) {
        console.log(data);
      this.phone = JSON.parse(window.localStorage.getItem('phone'))
      this.dialogVisible = true
      if (page == 'add') {
        this.title = '添加'
        this.isEdit = false
      } else {
        this.title = '编辑'
        this.isEdit = true

        Object.assign(this.ruleForm, data)


        this.optionsCheckbox.forEach((val)=>{
            var n = parseInt(data.modelValue) & val.label
            if(n > 0){
                this.ruleForm.optionsList.push(val.label)
            }
        })

        // this.$set(this.ruleForm, "optionsList", this.ruleForm.optionsList)


      }
    },
    getApi(api) {

        var param = {...this.ruleForm}
        if(param.sort){
            param.sort = parseInt(param.sort)
        }

        if(this.ruleForm.optionsList && this.ruleForm.optionsList.length > 0){
            var options = 0
            this.ruleForm.optionsList.forEach((val)=>{
                options = options | val
            })
            param.modelValue = options
        }

        this.$https(api, param).then(res => {
            this.$message.success(this.title + '成功')
            this.handleClose()
            this.$emit('ok')
        })
    },
    handleClose() {
      this.ruleForm = {
        key: '',
        type: '',
        state: '',
        apiType: '',
        sort: 0,
        url:'',
        embedding:0,
        optionsList:[]
      }
      this.dialogVisible = false
    },
    // 提交
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.title == '添加') {
            this.getApi('GPTKEYADD')
          }else{
            this.getApi('GPTKEYUPDETE')
          }
        }
      })
    },
    async loadAllModel(){
            
        var res = await this.$https('getModels', {})
        // console.log("所有模型",  res);
        this.optionsCheckbox = []
        res.data.forEach((val)=>{
            this.optionsCheckbox.push({
                label: parseInt(val.modelValue),
                name: val.name
            })
        })
    }

  }
}
</script>
<style lang="scss">
.upload_box {
  .el-upload-dragger {
    width: 460px !important;
  }
}
.tips{
    color: #f00;
    padding-left: 45px;
}
.key-value-box{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}
</style>
