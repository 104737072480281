<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-11-04 16:43:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-31 09:17:10
 * @FilePath: /siana_admin/src/views/gpt_key/components/list.vue
-->
<template>
  <div>
    <el-card shadow="never">
      <!-- 表格标题 -->
      <div
        class="tableHeader">
        <div class="left">
          GPTKEY管理
        </div>
        <!--操作按钮-->
        <div class="right">
          <el-button
            type="primary"
            @click="$refs.show.open('add')">添加</el-button>
        </div>
      </div>
      <!--
      表格组件
      @setColumns  //获取表头方法
      @selectChange // 勾选方法
      @changePage  // 切换页码方法
     -->
      <Card v-if="phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @changeState="changeState"
        @del="del"
        @changePage="changePage" />
      <Table v-if="!phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @changeState="changeState"
        @del="del"
        @keyMoney="keyMoney"
        @changePage="changePage" />
      <!--表格组件 end-->
      <ModalUpload ref="show"
        @ok="getData" />
    </el-card>

    <el-dialog :visible.sync="billingVisible" 
        :before-close="handleClose"  
        width="760px"
        center
        title="余额"
        append-to-body>

        <div class="wrap">
            <h3>剩余：${{hardLimitUsd-totalUsage}}（已用：${{totalUsage}}，总额度：${{hardLimitUsd}}）</h3>
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
        </span>

    </el-dialog>

  </div>
</template>

<script>
// 引入组件及方法
// 引入组件
import SearchForm from './search.vue'
import Table from './table.vue'
import Card from './card.vue'
import ModalUpload from './modal.vue'
export default {
  name: 'Yxb30uiIndex',
  components: { SearchForm, Table, ModalUpload, Card },
  // 数据
  data() {
    return {
        modelList: [],
      phone: false,
      // 页码
      type: '',
      pageNumber: 1, // 当前页
      // 页面尺寸
      pageSize: 30, // 每页显示条数
      // 当前页
      total: 0, // 数据总数
      // 表格数据
      tableData: [], // 列表数据
      // 表格数据 加载
      tableLoading: false, // 列表加载
      // 筛选项
      queryParams: {}, // 请求参数
      // 切换
      setColumnes: [], // 表头
      billingVisible:false,//key余额弹窗是否显示
      hardLimitUsd:0,//总额度
      totalUsage:0,//已用额度
      // 表头
      columns: [
        {
          title: '序号',
          fixed: 'left',
          minwidth: 50,
          key: 'index',
          align: 'center',
          // 工具提示
          tooltop: false
        },
        {
          title: '排序',
          key: 'sort',
          width: 50,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          title: 'KEY',
          key: 'key',
          minwidth: 200,
          // tooltop: true,
          // end
        },

        {
        //   title: 'KEY类型 / 使用次数 / 是否向量化',
          title: '使用次数',
          key: 'mult',
          minwidth: 240,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          title: '模型可用时间',
          key: 'availableTimes',
          minwidth: 200,
          // tooltop: true,
        },
        {
          title: 'API类型 / 三方url',
          key: 'apiType',
          minwidth: 200,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          title: '是否启用',
          key: 'state',
          width: 70,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        // {
        //   title: 'options',
        //   key: 'options',
        //   minwidth: 180,
        //   tooltop: true,
        //   isShow: true
        // },
        
        {
          title: '创建时间',
          key: 'createTime',
          minwidth: 180,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          title: '操作',
          key: 'actions',
          minwidth: 200,
          fixed: 'right',
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        }
      ]
    }
  },
  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    // 数据初始化
    this.loadAllModel()
  },

  methods: {
    async loadAllModel(){
        var res = await this.$https('getModels', {})
        this.modelList = res.data
        this.getData()
    },
    // 获取数据
    getData() {
      this.$https('GPTKEYLIST', {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }).then(res => {
        const now = new Date();
        this.tableData = res.data.records
        this.tableData.forEach((val, index)=>{
            if(val.availableTimes){
                val.modelData = []
                for(let key in val.availableTimes){
                    this.modelList.forEach(item=>{
                        if(item.modelValue==key){
                            var timestamp = new Date(val.availableTimes[key]).getTime()
                            var openTime = ''
                            if(timestamp>now){
                                openTime = (timestamp-now) / 1000;
                            }
                            val.modelData.push({
                                modelValue: item.modelValue,
                                modelName: item.name,
                                openTime: openTime,
                            })
                        }
                    })
                }
            }
            val.mult = ''
            // var gptStr = val.type == 3 ? "GPT3.5": "GPT4"
            // if(val.embedding == 1){
            //     val.mult = `${gptStr} / ${val.useNumber}次 / 向量化`
            // }else{
            //     val.mult = `${gptStr} /  ${val.useNumber}次`
            // }
        })
        this.total = parseInt(res.data.total)
      })
    },
    // 打开编辑弹窗
    edit(row) {
      console.log(row)

      this.$refs.show.open('edit', row)

    //   this.$https('GPTKEYUPDETE', {
    //     id: row.id,
    //     state: row.state
    //   }).then(res => {
    //     if (res.status == 200) {
    //       this.$message.success('操作成功')
    //       this.getData()
    //     }
    //   })
    },
    changeState(row){
        console.log(row)
        this.$https('GPTKEYUPDETE', row).then(res => {
            this.$message.success('成功')
        })
    },
    keyMoney(row){
      this.hardLimitUsd = 0
      this.totalUsage = 0
      this.$https("GPTKEYBILLING", {key: row.key}).then(res => {
        console.log(res)
        if(res.status == 200){
          this.billingVisible = true
          this.hardLimitUsd = res.data.hardLimitUsd
          this.totalUsage = res.data.totalUsage
        }
        
      })
    },
    // 删除项
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$https('GPTKEYDELETE', { ids: [row.id] }).then(res => {
          this.getData()
          this.$message.success('删除成功')
        })
      })
    },
    // 翻页
    changePage(data) {
      this.pageNumber = data.pageNumber
      this.pageSize = data.pageSize
      this.getData()
    },
    // 搜索
    search(data) {
      this.pageNumber = 1
      this.queryParams = data
      this.getData()
    },
    handleClose(){
      this.billingVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>

.wrap{
  text-align: center;
  padding: 90px 0;
}

</style>
